import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';

import Layout from '../components/Layout';
import Video from '../components/Video';
import Post from '../components/Post';

const Media = () => {
  const [posts, updatePosts] = useState();
  const [videos, updateVideos] = useState();
  useEffect(() => {
    fetch(
      'https://www.googleapis.com/youtube/v3/search?part=snippet&channelId=UCTUuyVBYiopWoTEJu6ptDbg&maxResults=5&order=date&type=video&key=AIzaSyCvQ4AlnZSPisZKGABTUoizbnfgTatxZP4'
    )
      .then(res => res.json())
      .then(data => updateVideos(data.items))
      .catch(err => updateVideos({ error: err }));

    fetch('https://blogwell.io/feed.json')
      .then(res => res.json())
      .then(data => updatePosts(data.items.slice(0, 5)))
      .catch(err => updatePosts({ error: err }));
  }, []);
  return (
    <Layout title='Media'>
      <div
        css={css`
          display: flex;
          flex-direction: row;
          align-content: space-between;
          @media (max-width: 1000px) {
            flex-direction: column;
          }
        `}
      >
        <section>
          <h2>Latest posts</h2>
          {posts &&
            !posts.error &&
            posts.map(post => <Post key={post.id} data={post} />)}
        </section>
        <section>
          <h2>Latest videos</h2>
          {videos &&
            !videos.error &&
            videos.map(vid => <Video key={vid.id.videoId} data={vid} />)}
        </section>
      </div>
    </Layout>
  );
};

export default Media;
import React from 'react';
import { css } from '@emotion/react';

export default ({ data }) => (
  <div
    css={css`
      padding: 25px 5px;
      a {
        text-decoration: underline;
        font-weight: 800;
      }
      div {
        padding-top: 5px;
      }
    `}
  >
    <a href={data.id}>{data.title}</a>
    <div>
      <small>{data.summary}</small>
    </div>
  </div>
);

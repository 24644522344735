import React from 'react';
import { css } from '@emotion/react';

export default ({ data }) => (
  <>
    <div
      css={css`
        padding: 25px 5px;
        a {
          text-decoration: underline;
          font-weight: 800;
        }
        div {
          padding-top: 5px;
        }
      `}
    >
      <a href={`https://www.youtube.com/watch?v=${data.id.videoId}`}>
        {data.snippet.title}
      </a>
      <div>
        <small>{data.snippet.description}</small>
      </div>
    </div>
  </>
);
